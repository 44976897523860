@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  background-color: #5c7566;
  font-family: "Cormorant Garamond", serif;
}

.img {
  border-radius: 100%;
}
